<template>
    <div class="loading-circle"></div>
</template>

<script>
    export default {
        name: "CustomLoader"
    }
</script>

<style scoped>
    .loading-circle {
        width: 150px;
        height: 150px;
        border-radius: 100%;
        border: none;
        background: url("../assets/loader.svg");
        background-size: cover;
        animation: circleanimation 1.5s linear infinite
    }
    @keyframes circleanimation {
        from {
            transform: rotateZ(0deg);
        }
        to {
            transform: rotateZ(360deg);
        }
    }
</style>
