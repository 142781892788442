import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueLoading from 'vuejs-loading-plugin';
import CustomLoader from "./components/CustomLoader";
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueLoading, {
  classes: 'custom-loader',
  customLoader: CustomLoader,
  background: 'rgba(255, 255, 255, 0.3)',
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
